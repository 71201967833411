@import "../../css/utilities/variables";
@import "../../css/utilities/mixins";
@import "../../css/utilities/responsive";

.step-money {
  background: white;
  margin-top: $spacing-r;
  border-radius: 0 0 $basic-radius $basic-radius;
  @include bp-medium {
    margin-top: $spacing-m;
  }
  .step-money-result {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: $accent;
    color: white;
    padding: $spacing-r;
    border-radius: 0 0 $basic-radius $basic-radius;
    @include bp-medium {
      padding: $spacing-m;
    }
    .title {
      font-size: calculateRem(20px);
    }
    .result {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      font-size: calculateRem(20px);
      @include bp-small {
        flex-direction: row;
        align-items: center;
      }
      span + span {
        margin: $spacing-s 0 0 0;
        @include bp-small {
          margin: 0 0 0 $spacing-m;
          position: relative;
          &:before {
            display: inline-block;
            content: "";
            height: calculateRem(16px);
            width: 1px;
            background: white;
            position: absolute;
            left: -12px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
      small {
        font-size: 60%;
      }
    }
  }
}
