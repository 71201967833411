mat-dialog-container.mat-dialog-container {
  border-radius: $basic-radius;
  max-height: calc(100vh - 40px);
  overflow-y: auto;
}

.full-screen-dialog {
  max-width: calc(100% - 48px) !important;
}

// No padding dialog modal
.no-padding-dialog {
  .mat-dialog-container {
    padding: 0;
    background: none;
  }
}

.no-shadow-dialog .mat-dialog-container {
  box-shadow: none;
}
