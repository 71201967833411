@import "../../css/utilities/variables";
@import "../../css/utilities/mixins";

mat-stroked-button {
  &.mat-stroked-button {
    border-radius: $basic-radius;
  }
}

// White liseret button
.btn-invert.mat-stroked-button[_ngcontent-c18]:not([disabled]) {
  border-color: white;
  color: white;
}

.btn {
  position: relative;
  font-size: calculateRem(14px);
  line-height: calculateRem(34px);
  padding: 0 $spacing-s;
  background: transparent;
  border: $basic-border;
  color: $text-color;
  border-radius: $basic-radius;
  cursor: pointer;
  z-index: 1;
  overflow: hidden;
  @include bp-medium {
    padding: 0 $spacing-r;
  }
  &:before {
    display: block;
    content: "";
    position: absolute;
    width: 100%;
    height: 0;
    left: 0;
    top: 0;
    background: $extralight-gray;
    @include transition(0.2);
    z-index: -1;
  }
  &:not([disabled]):focus,
  &:not([disabled]):hover {
    &:before {
      height: 100%;
    }
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }
}

.btn-big {
  padding: $spacing-m;
}

a.btn {
  text-decoration: none;
}

.btn-link {
  border: none;
  padding: 0;
  background: none;
  line-height: inherit;
  text-decoration: underline;
  &:before {
    display: none;
  }
  &:not([disabled]):hover,
  &:not([disabled]):focus {
    color: $accent;
  }
}

.btn-invert {
  color: white;
  border-color: white;
  background: transparent;
  &:before {
    background: white;
  }
  &:not([disabled]):focus,
  &:not([disabled]):hover {
    color: $accent;
  }
}

.btn-primary {
  background: $primary;
  color: white;
  border-color: $primary;
  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
  &:before {
    background: $primary-darken;
  }
}

.btn-accent {
  background: $accent;
  color: white;
  border-color: $accent;
  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
  &:before {
    background: $accent-darken;
  }
}

.btn-warning {
  background: $warning;
  color: white;
  border-color: $warning;
  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
  &:before {
    background: $warning-darken;
  }
}
